.rangeMode {
  right: auto !important;
  width: 101.3% !important;
  border-radius: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;
  top: -380px !important;
  left: -2px !important;
  border: 2px solid $grey;
  padding: 14px !important;
  background-color: $white !important;

  @include desktop {
    top: -368px !important;
  }

  &::before {
    content: none !important;
  }

  &::after {
    content: none !important;
  }
}

.rangeMode .flatpickr-prev-month {
  transform: translateX(-17px) !important;
}

.rangeMode .flatpickr-next-month {
  transform: translateX(20px) !important;
}
.rangeMode .dayContainer {
  max-width: unset !important;
  width: 100% !important;
  justify-content: flex-start;
  min-width: unset !important;
}

.rangeMode .flatpickr-days {
  width: 100% !important;
}

.rangeMode .flatpickr-innerContainer {
  display: flex !important;
  flex-direction: column !important;
  max-width: 302px !important;
  margin: 0 auto !important;
  background-color: white !important;
  justify-content: center !important;

  @include tablet {
    max-width: calc(500px - 38px) !important;
  }

  @include desktop {
    margin: 0 !important;
    margin-right: auto !important;
  }
}

.rangeMode .flatpickr-months {
  max-width: 302px !important;
  margin: 0 auto !important;
  background-color: white !important;
  padding: 14px 14px 0 14px !important;
  margin-bottom: 13px !important;

  @include tablet {
    max-width: calc(500px - 38px) !important;
  }

  @include desktop {
    margin: 0 !important;
    margin-right: auto !important;
    padding: 20px 20px 0;
    padding-bottom: 13px !important;
  }
}

.flatpickr-prev-month {
  position: static !important;
  display: block !important;
}

.flatpickr-monthDropdown-months option {
  display: none;
}

.numInputWrapper {
  appearance: none !important;
  pointer-events: none;
  &:hover {
    background-color: none !important;
  }
}

.cur-year {
  transform: translateX(42px);
}

.flatpickr-monthDropdown-months {
  appearance: none !important;

  &:hover {
    background-color: transparent !important;
    cursor: auto;
  }
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange {
  border-radius: 0;
  background-color: $blue;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange:hover {
  border-radius: 0;
  background-color: $blue;
}

.flatpickr-day.selected.endRange,
.flatpickr-day.endRange.endRange {
  border-radius: 0;
  background-color: $blue;
}

.flatpickr-day.selected.startRange,
.flatpickr-day.startRange.startRange:hover {
  border-radius: 0;
  background-color: $blue;
}

.flatpickr-day:hover {
  border-radius: 0;
  background-color: $grey;
}

.rangeMode .flatpickr-day {
  border-radius: 0;
  max-width: 46px !important;
}

.flatpickr-day.selected.startRange.endRange,
.flatpickr-day.startRange.startRange.endRange,
.flatpickr-day.endRange.startRange.endRange {
  border-radius: 0;
}

.today {
  border: 1px solid $blue;
  border-radius: 0;
}

.rangeMode .numInput {
  margin-left: -55px !important ;
  padding: 0 !important;
}

.flatpickr-weekday {
  color: $blue !important;
  font-size: 100% !important;
}

.flatpickr-months .flatpickr-next-month:hover svg {
  fill: $blue;
}

.flatpickr-months .flatpickr-prev-month:hover svg {
  fill: $blue;
}

.rangeMode .flatpickr-next-month {
  position: static !important;
  transform: translateX(5px);
  @include desktop {
    transform: translateX(-10px);
  }
}

.rangeMode .flatpickr-current-month {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transform: translateX(-20px);
}

.flatpickr-monthDropdown-months {
  padding: 0 !important;
  text-align: center !important;
}

// Single date

.time-container {
  text-align: left;
  color: $blue;
  font-size: 16px;
  margin: 0 auto 14px;
  border: none;
  outline: none;
  cursor: pointer;
  width: 100%;
  display: flex;

  &::placeholder {
    color: $blue;
  }

  &:hover {
    text-decoration: underline;
  }
}

.time-picker .arrowBottom {
  top: 397px !important;
  left: 0 !important;
  right: auto !important;
  width: 100% !important;
  border-radius: 0 !important;
  margin: 0 !important;
  background-color: transparent !important;
  box-shadow: none !important;

  @include desktop {
    width: 42% !important;
    margin-left: 35.3% !important;
    top: 421px !important;
  }

  @include desktop-medium {
    top: 429px !important;
    width: 39.4% !important;
    margin-left: 36.2% !important;
  }

  @include desktop-large {
    width: 35.7% !important;
    margin-left: 37.5% !important;
  }

  &::before {
    content: none !important;
  }

  &::after {
    content: none !important;
  }
}

.time-picker .arrowBottom .flatpickr-innerContainer {
  width: 90% !important;
  max-width: 302px !important;
  margin: 0 auto !important;
  background-color: white !important;
  justify-content: center !important;
  border-bottom: 2px solid $blue !important;
  border-left: 2px solid $blue !important;
  border-right: 2px solid $blue !important;

  @include tablet {
    width: 70% !important;
    max-width: calc(500px - 38px) !important;
  }

  @include desktop {
    margin: 0 !important;

    margin-right: auto !important;
  }
}

.time-picker .arrowBottom .flatpickr-months {
  width: 90% !important;
  max-width: 302px !important;
  margin: 0 auto !important;
  background-color: white !important;
  border-top: 2px solid $blue !important;
  border-left: 2px solid $blue !important;
  border-right: 2px solid $blue !important;

  @include tablet {
    width: 70% !important;
    max-width: calc(500px - 38px) !important;
  }

  @include desktop {
    margin: 0 !important;
    margin-right: auto !important;
  }
}

.time-picker .arrowBottom .flatpickr-prev-month {
  position: static !important;
}

.time-picker .arrowBottom .flatpickr-next-month {
  position: static !important;
}

.time-picker .arrowBottom .flatpickr-current-month {
  width: 100% !important;
  display: flex !important;
  justify-content: unset !important;
  align-items: center !important;
}

.time-picker .arrowBottom .flatpickr-monthDropdown-months {
  padding: 0 !important;
}

.calendar-dayuse {
  top: -360px !important;
  width: 101.3% !important;
  left: -2px !important;
  border-radius: 0 !important;
  border: 2px solid $grey;
  box-shadow: none !important;

  @include desktop {
    top: -348px !important;
  }

  &::before {
    content: none !important;
  }

  &::after {
    content: none !important;
  }
}

.calendar-dayuse .flatpickr-next-month {
  position: static !important;
}

.calendar-dayuse .flatpickr-next-month {
  position: static !important;
}

.calendar-dayuse .flatpickr-current-month {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  transform: translateX(-27px);
}

.calendar-dayuse .flatpickr-monthDropdown-months {
  padding: 0 !important;
}

.calendar-dayuse .cur-year {
  transform: translateX(0) !important;
}

.calendar-dayuse .dayContainer {
  max-width: unset !important;
  width: 100% !important;
  justify-content: flex-start;
  min-width: unset !important;
}

.calendar-dayuse .flatpickr-days {
  width: 100% !important;
}

.calendar-dayuse .flatpickr-day {
  max-width: 47px !important;
}

.calendar-dayuse .flatpickr-innerContainer {
  display: flex !important;
  flex-direction: column !important;
  margin: 0 auto !important;
  background-color: white !important;
  justify-content: center !important;
  // border-bottom: 1px solid $grey !important;
  // border-left: 1px solid $grey !important;
  // border-right: 1px solid $grey !important;
  padding: 0 14px 14px 14px;

  @include tablet {
  }

  @include desktop {
    margin: 0 !important;
    margin-right: auto !important;
  }
}

.calendar-dayuse .flatpickr-prev-month {
  transform: translateX(-2px) !important;

  @include desktop {
    transform: translateX(1px) !important;
  }
}

.calendar-dayuse .flatpickr-next-month {
  transform: translateX(9px) !important;

  @include desktop {
    transform: translateX(2px) !important;
  }
}

.calendar-dayuse .flatpickr-months {
  max-width: 302px !important;
  background-color: white !important;
  // border-top: 1px solid $grey !important;
  // border-left: 1px solid $grey !important;
  // border-right: 1px solid $grey !important;
  padding: 14px 14px 0;
  margin-bottom: 13px !important;

  @include tablet {
    max-width: calc(500px - 38px) !important;
  }

  @include desktop {
    margin: 0 !important;
    margin-right: auto !important;
    padding-bottom: 13px !important;
  }
}

.calendar-dayuse .selected {
  background-color: $blue;
}

.time-arrive {
  width: 100px !important;
  top: -8px !important;
  left: 165px !important;
  background: $white !important;
  box-shadow: none !important;

  @include desktop {
    top: -9px !important;
    left: 220px !important;
  }

  &::before {
    content: none !important;
  }

  &::after {
    content: none !important;
  }
}

.time-arrive2 {
  width: 100px !important;
  top: -8px !important;
  left: 165px !important;
  background: $white !important;
  box-shadow: none !important;

  @include desktop {
    top: -9px !important;
    left: 220px !important;
  }

  &::before {
    content: none !important;
  }

  &::after {
    content: none !important;
  }
}

.time-arrive .numInputWrapper {
  pointer-events: all;

  &:hover {
    background-color: $white !important;
  }
}

.time-arrive2 .numInputWrapper {
  pointer-events: all;
  &:hover {
    background-color: $white !important;
  }
}

.time-arrive .time24hr {
  border-top: 0 !important;
}

.time-arrive2 .time24hr {
  border-top: 0 !important;
}

.time-arrive .flatpickr-time input:hover {
  background: none !important;
}

.time-arrive2 .flatpickr-time input:hover {
  background: none !important;
}

.time-arrive .numInputWrapper:hover {
  background: none !important;
}

.time-arrive2 .numInputWrapper:hover {
  background: none !important;
}

.time-arrive .numInput {
  height: 30px;
  background-color: $whiteHotel !important;
}

.time-arrive2 .numInput {
  height: 30px;
  background-color: $whiteHotel !important;
}

.flatpickr-calendar {
  animation: none !important;
}

.numInputWrapper:not(:hover) {
  background-color: $white;
}

.flatpickr-time .numInputWrapper {
  height: 25px !important;
}

.flatpickr-time-separator {
  margin: 0 5px;
}

.calendar-dayuse .flatpickr-months {
  width: 100% !important;
  background: transparent !important;
}

.flatpickr-innerContainer {
  width: 100% !important;
}

.arrowUp {
  top: 4px !important;
}

.arrowDown {
  top: 88% !important;
}

.calendar-dayuse .flatpickr-monthDropdown-months {
  width: 100px !important;
  transform: translateX(7px) !important;
}

.flatpickr-input {
  display: flex;
  height: 100%;
}

.flatpickr__padding {
  @include spacing-align(padding, 12, 15);

  @include desktop {
    @include spacing-align(padding, 12, 6);
  }

  @include desktop-medium {
    @include spacing-align(padding, 16, 15);
  }
}

.flatpickr__padding-text {
  @include spacing-align(padding, 12, 0);

  @include desktop {
    @include spacing-align(padding, 12, 0);
  }

  @include desktop-medium {
    @include spacing-align(padding, 16, 0);
  }
}

.flatpickr__input-text {
  font-family: bold;
  margin-right: 0;
  cursor: pointer;
  width: 140px;
  background-color: $white;

  @include desktop {
  }

  &::placeholder {
    color: $blue;
  }
  &--arrive {
    width: 68px;
    @include desktop {
      width: 78px;
    }

    @include desktop-large {
      width: 88px;
    }
  }
}

.flatpickr-current-month {
  font-size: 136% !important;
}

.flatpickr-wrapper {
  width: 100%;
}

.flatpickr-input {
  width: 100%;
}

#js-calendar-panel-2 {
  width: 100%;
}

#js-dayuse-hamburger-hour {
  width: 100%;
}

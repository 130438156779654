.slider-home {
  height: 460px;
  font-family: bold;

  @include desktop {
    height: 650px;
  }

  &__track {
    height: inherit;
  }

  &__slides {
    height: inherit;
    cursor: grab;

    @include desktop {
      cursor: auto;
    }
  }

  &__arrow {
    display: none;
  }

  &__img-container {
    height: inherit;
    position: relative;

    @include desktop {
    }
  }

  &__img {
    height: inherit;
    @include fit-image;
    cursor: grab;

    &:active {
      cursor: grabbing;
    }
  }

  &__text {
    position: absolute;
    bottom: 35px;
    left: 30px;
    @include clr($white);
    font-size: $font31;
    line-height: $lHeight34;
    @include mono-spacing(padding, bottom, 24);
    border-bottom: 2px solid $white;
    word-break: normal;
    text-align: left;
  }

  &__icon-container {
    position: absolute;
    top: 17px;
    right: 0;
  }
}

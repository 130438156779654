.slider-room {
  height: 222px;
  font-family: bold;

  @include desktop {
    height: 600px;
  }

  &__track {
    height: inherit;
  }

  &__slides {
    height: inherit;
    cursor: grab;

    @include desktop {
      cursor: auto;
    }
  }

  &__arrows {
    width: 100%;
    position: absolute;
    display: flex;
    top: -64px;
  }

  &__arrow {
    &--left {
      display: none;
    }

    &--right {
      display: block;
      position: static;
      margin: 0 auto;
      z-index: 3;
      transform: translate(73px, 17px);
      background-color: transparent;
      border: 0;
      box-shadow: none;

      @include desktop {
        transform: translate(80px, 5px);
      }

      &:hover .right-arrow {
        transform: translateX(10px);
      }
    }

    &--right-container {
      width: 100%;
      position: absolute;
      display: flex;
      top: -64px;
    }
  }

  &__img-container {
    height: inherit;
    position: relative;

    @include desktop {
    }
  }

  &__img {
    height: inherit;
  }

  &__text {
    position: absolute;
    bottom: 35px;
    left: 30px;
    @include clr($white);
    font-size: $font31;
    line-height: $lHeight34;
    @include mono-spacing(padding, bottom, 24);
    border-bottom: 2px solid $white;
    word-break: normal;
  }
}

.slider-room__arrow--right:hover .room__gallery-text > i {
  transform: translateX(10px) !important;
}

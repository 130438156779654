.menu {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  background-color: $blue;

  @include desktop {
    height: unset;
    //flex-direction: row;
    padding-bottom: 110px;
  }

  &__button {
    margin-bottom: 90px;
    color: $whiteHotel;
    background-color: $whiteHotel;
    padding: 40px;
    width: 500px;
    text-align: center;
    font-size: 40px;
    line-height: 30px;
    color: $blue;
    font-family: bold;

    &:last-child {
      margin-bottom: 0;
    }

    @include desktop {
      margin-right: 0;
      margin-bottom: 40px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  &__img {
    width: 100%;
    max-width: 500px;
    margin-bottom: 110px;
    margin-top: 110px;

    @include desktop {
      max-width: 400px;
    }
  }

  &__container {
    width: 100%;
    height: 42vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}

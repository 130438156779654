.service {
  @include desktop {
    display: flex;
  }

  &__card {
    width: 100%;
    text-align: center;
    height: 360px;
    display: flex;
    flex-direction: column;

    @include desktop {
      width: 50%;
      height: 360px;

      &--wide {
        width: 100%;
        height: 360px;
        max-width: unset;
      }
    }

    &:nth-child(3) {
      display: none;

      @include desktop {
        display: flex;
        justify-content: center;
      }
    }

    &--padding {
      @include spacing-align(padding, 60, 60);

      @include desktop {
        @include spacing-align(padding, 25, 20);
      }

      @include desktop-medium {
        @include spacing-align(padding, 25, 47);
      }

      @include desktop-large {
        @include spacing-align(padding, 50, 47);
      }
    }

    &--blue {
      @include bkgr($blue);
    }

    &--yellow {
      @include bkgr($yellowHotel);
    }

    &--flex {
      display: flex;
      align-items: center;
    }
  }

  &__cards-container {
    @include desktop {
      display: flex;
      width: 50%;
      flex-wrap: wrap;
    }
  }

  &__img {
    @include fit-image;
    height: 100%;
  }

  &__title {
    @include clr($blue);
    font-size: $font31;
    line-height: $lHeight34;
    @include mono-spacing(margin, bottom, 40);

    &--yellow {
      @include clr($yellowHotel);
    }
  }

  &__text {
    font-size: $font15;
    line-height: $lHeight20;
    @include mono-spacing(margin, bottom, auto);

    @include desktop {
      font-size: 1.6rem;
      line-height: 24px;
    }

    @include desktop-medium {
      font-size: $font20;
    }
  }

  &__icon-container {
    //max-width: 60px;
    //border-radius: 100px;
    //@include bkgr($white);
    background-color: transparent;
    //@include spacing-align(padding, 10, 20);
    @include spacing-align(margin, 0, auto);
    //box-shadow: 4px 13px 25px rgba(53, 101, 117, 0.15), -5px -18px 18px #ffffff;
  }

  &__icon {
    //width: 24px;
    fill: $yellowHotel;
  }

  &__link-container {
    display: flex;
    justify-content: center;
  }

  &__link {
    max-width: 140px;
    @include spacing-align(margin, 0, auto);
  }
}

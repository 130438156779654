.room {
  @include spacing(padding, 20, 0, 0, 0);
  text-align: center;

  @include desktop {
    @include spacing(padding, 30, 0, 0, 0);
  }

  &__title {
    font-size: $font31;
    line-height: $lHeight34;
    @include clr($blue);
    @include mono-spacing(margin, bottom, 10);

    @include desktop {
      font-size: $font42;
      line-height: $lHeight46;
      @include mono-spacing(margin, bottom, 12);
    }

    &--yellow {
      @include clr($yellowHotel);
    }
  }

  &__gallery-text {
    font-size: $font15;
    line-height: $lHeight19;
    display: flex;
    align-items: center;
    justify-content: center;
    @include mono-spacing(margin, bottom, 20);

    @include desktop {
      font-size: $font20;
      line-height: $lHeight22;
      @include mono-spacing(margin, bottom, 30);
    }
  }

  &__gallery-text > i {
    margin-left: 10px;
    padding-top: 7px;
  }

  &__text-wrapper {
    width: 100%;
    background-color: $blue;
  }

  &__text-container {
    @include bkgr($blue);
    @include spacing-align(padding, 45, 42);
    @include clr($white);
    @include mono-spacing(margin, bottom, 30);
    font-size: $font15;
    line-height: $lHeight16;
    position: relative;

    @include desktop {
      @include bkgr(transparent);
      @include clr($black);
      width: 85%;
      max-width: 1169px;
      @include spacing-align(padding, 45, 0);
      @include spacing-align(margin, 0, auto);
      font-size: $font21;
      line-height: 25px;
    }

    &--bkgr-yellow {
      @include bkgr($yellowHotel);

      @include desktop {
        @include bkgr(transparent);
      }
    }
  }

  &__text {
    color: $white;
    font-size: 1.9rem;
    line-height: 24px;
  }

  &__line {
    @include spacing-align(margin, 0, auto);
    width: 265px;
    height: 2px;
    border-color: $blue;
    @include bkgr($blue);
    border-width: 0;

    @include desktop {
      width: 430px;
    }

    &--yellow {
      @include bkgr($yellowHotel);
    }
  }

  &__icons-wrapper {
    width: 85%;
    max-width: 1169px;
    @include spacing-align(margin, 0, auto);
    @include mono-spacing(margin, bottom, 60);

    @include desktop {
      @include mono-spacing(margin, bottom, 60);
      margin-top: 60px;
    }
  }

  &__icon-info-wrapper {
    position: absolute;
    z-index: 2;
    width: 100%;
    top: -24px;
    left: 0;
    @include desktop {
      top: 31px;
      left: 32px;
    }
  }

  &__icon-info {
    margin: 0 auto;
    max-width: 62px;

    @include desktop {
      position: absolute;
      bottom: 10px;
      left: 0;
    }
  }
}

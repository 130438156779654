.hero {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    display: block;
    background-color: rgba(44, 41, 42, 0.15);
    z-index: 2;
    width: 100%;
    bottom: 0;
    height: 100%;

    @include desktop {
      height: calc(100% - 55px);
    }
  }

  &__img-container {
    height: 650px;
  }

  &__img {
    width: 100%;
    @include fit-image;
  }

  &__content-wrapper {
    position: absolute;
    top: 60px;
    width: 100%;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;

    @include desktop {
      top: 190px;
      height: calc(100% - 190px);
    }
  }

  &__logo-container {
    max-width: 175px;
    @include spacing-align(margin, 0, auto);
    @include mono-spacing(margin, bottom, 30);
    z-index: 3;

    @include desktop {
      max-width: 253px;
    }
  }

  &__content-title {
    text-align: center;
    @include clr($white);
    font-size: $font25;
    line-height: $lHeight27;
    max-width: 244px;
    @include spacing-align(margin, 0, auto);
    z-index: 3;

    @include desktop {
      max-width: unset;
      font-size: $font42;
      line-height: $lHeight46;
    }
  }

  &__content-page-wrapper {
    display: flex;
    @include bkgr($white);
    width: 90.7%;
    @include spacing(margin, auto, auto, 0, auto);
    padding: 14px 20px;
    height: 60px;

    @include desktop {
      padding: 6px 20px;
    }
  }

  &__content-page-container {
    display: flex;
    width: 80%;
    @include spacing(margin, 0, auto, 0, auto);
    justify-content: center;
    align-items: center;
  }

  &__content-page-line {
    margin: 0;
    width: 100%;
    max-width: 111px;
    border: 0;
    height: 2px;
    @include bkgr($blue);
    border-color: $blue;
    @include spacing(margin, auto, 0, auto, 0);
  }

  &__content-page {
    @include clr($blue);
    font-size: $font15;
    line-height: $lHeight16;
    text-align: center;
    max-width: 221px;
    @include spacing(margin, auto, 0, auto, 0);
    margin-left: 3.5%;
    margin-right: 3.5%;

    @include desktop {
      font-size: $font20;
      line-height: $lHeight27;
    }
  }

  &__content-page-icon {
    display: none;
    cursor: auto;

    @include desktop {
      display: block;
      margin-left: 3.5%;
    }
  }
}

.footer {
  width: 100%;
  @include spacing(padding, 50, 40, 120, 40);

  @include desktop {
    width: 85%;
    max-width: 1169px;
    margin: 0 auto;
    @include spacing(padding, 53, 0, 86, 0);
  }

  &__logo-social-wrapper {
    display: flex;
    align-items: center;
    @include mono-spacing(padding, bottom, 18);
    border-bottom: 1px solid $yellowHotel;
    @include mono-spacing(margin, bottom, 29);

    @include tablet {
      @include mono-spacing(margin, bottom, 55);
    }
    @include desktop {
      border-bottom: none;
      padding-bottom: 0;
      margin-bottom: 54px;
    }
  }

  &__logo-container {
    width: 100%;
    max-width: 107px;
    @include mono-spacing(margin, right, auto);

    @include tablet {
      max-width: 167px;
    }

    @include desktop {
      padding-bottom: 44px;
      border-bottom: 1px solid $yellow;
      max-width: 300px;
    }
  }

  &__logo {
    @include desktop {
      max-width: 167px;
    }
  }

  &__social-container {
    display: flex;
    align-items: center;
    width: 100%;
    max-width: 150px;

    @include tablet {
      max-width: 450px;
    }

    @include desktop {
      transform: translateY(11px);
      border-bottom: 1px solid $yellow;
      padding-bottom: 50px;
    }
  }

  &__social-card {
    @include mono-spacing(margin, right, auto);

    &:last-child {
      @include mono-spacing(margin, right, 0);
    }

    @include desktop {
      width: 60px;
      height: 60px;
    }
  }

  &__social {
    @include fit-image;
    display: flex;
    align-items: center;

    &--facebook {
      width: 10px;

      @include desktop {
        width: 19px;
      }
    }

    &--instagram {
      width: 23px;
      @include desktop {
        width: 48px;
      }
    }

    &--trip {
      width: 36px;
      @include desktop {
        width: 82px;
      }
    }

    &--tiktok {
      width: 26px;

      @include desktop {
        width: 49px;
      }
    }
  }

  &__content-wrapper {
    text-align: center;
    @include clr($white);

    @include desktop {
      text-align: left;
      display: flex;
    }
  }

  &__content-title {
    font-size: $font20;
    line-height: $lHeight22;
    @include mono-spacing(margin, bottom, 25);

    @include tablet {
      font-size: $font31;
      line-height: $lHeight34;
      max-width: 250px;
      @include spacing-align(margin, 0, auto);
      @include mono-spacing(margin, bottom, 107);
    }

    @include desktop {
      @include mono-spacing(margin, left, 0);
    }
  }

  &__content-text {
    font-size: $font15;
    line-height: $lHeight16;
    @include mono-spacing(margin, bottom, 36);

    @include tablet {
      font-size: $font20;
      line-height: $lHeight22;

      @include mono-spacing(margin, bottom, 50);
    }
  }

  &__form-container {
    @include mono-spacing(margin, bottom, 56);
  }

  &__form-input {
    width: 100%;
    @include bkgr(transparent);

    border: none;
    border-bottom: 2px solid $white;
    @include mono-spacing(padding, bottom, 18);
    outline: 0;
    @include clr($white);
    font-size: $font15;
    line-height: $lHeight16;

    &::placeholder {
      @include clr($white);
      font-size: $font15;
      line-height: $lHeight16;
    }
  }

  &__news-wrapper {
    max-width: 450px;
    @include spacing-align(margin, 0, auto);

    @include desktop {
      @include spacing(margin, 0, 100, 0, 0);
    }
  }

  &__info-wrapper {
    @include desktop {
      display: flex;
      width: 100%;
    }
  }

  &__list {
    text-align: left;
    @include mono-spacing(margin, right, auto);
    margin-top: 37px;

    &--mrg {
      margin-bottom: 29px;

      @include desktop {
        margin-bottom: 0;
      }
    }

    @include desktop {
      margin-top: 0;
      margin-bottom: 0;
    }

    &--no-mrg {
      margin-top: 0;
      @include desktop {
        margin-right: 120px;
      }
    }
  }

  &__info-list-wrapper {
    margin-right: auto;

    &:last-child {
      margin-right: 0;
    }

    @include desktop {
      display: flex;
      margin-right: 0;

      &:first-child {
        width: 75%;
      }

      &:last-child {
        width: 47%;
      }
    }
  }

  &__info-list {
    text-align: left;

    @include desktop {
      text-align: left;
      margin-right: auto;
      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__list-item-header {
    font-size: $font20;
    line-height: $lHeight22;
    text-align: left;
    @include mono-spacing(margin, bottom, 27);

    @include tablet {
      font-size: $font31;
      line-height: $lHeight34;
      @include mono-spacing(margin, bottom, 41);
    }

    &--text-right {
      text-align: right;
    }
  }

  &__list-item {
    font-size: $font15;
    line-height: $lHeight16;
    @include mono-spacing(margin, bottom, 8);

    @include tablet {
      font-size: $font20;
      line-height: $lHeight22;
      @include mono-spacing(margin, bottom, 13);
    }

    &--underline {
      display: block;
      margin-bottom: 8px;
      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__list-item-icon {
    @include bkgr($white);
    max-width: 60px;
    @include clr($blue);
    @include spacing(margin, 0, 0, 34, 0);
    @include mono-spacing(padding, left, 0);
    border-radius: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    @include spacing-align(padding, 9, 8);
    transition: padding 300ms;

    @include desktop {
      @include spacing(margin, 0, 0, 34, auto);
    }

    &:hover {
      @include mono-spacing(padding, left, 15);
    }
  }

  &__list-item-address {
    @include mono-spacing(margin, top, 26);
    font-size: $font15;
    line-height: $lHeight22;

    @include desktop {
      margin-top: 0;
    }
  }

  &__copyright {
    color: $white;
    margin-top: 45px;
    padding-top: 42px;
    border-top: 1px solid $yellow;
    @include desktop {
      margin-top: 55px;
      border: 0;
    }
  }

  &__copyright-text {
    letter-spacing: 0.5px;
    text-align: center;
    font-size: 14px;
    line-height: 22px;
    margin: 0 auto;

    @include desktop {
      text-align: left;
      width: unset;
      margin: unset;
      font-size: 16px;
      line-height: 12px;
    }
  }

  &__copyright-company {
    font-family: bold;
    color: $yellowHotel;
  }

  &__contact {
    padding-bottom: 17px;

    @include desktop {
      padding-bottom: 90px;
    }
  }

  &__disclaimer {
    letter-spacing: 0.5px;
    text-align: center;
    font-size: 12px;
    line-height: 22px;
    margin: 40px auto 0;

    @include desktop {
      text-align: left;
      width: unset;
      margin: unset;
      margin-top: 40px;
      font-size: 14px;
      line-height: 22px;
    }
  }

  &__disclaimer-link {
    color: $yellowHotel;
    &:hover {
      text-decoration: underline;
    }
  }
}

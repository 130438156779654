#tidio-chat-iframe {
  inset: auto 0 45px auto !important;

  @include desktop {
    inset: auto 0 45px auto !important;
  }
}

.chat,
.start-group {
  width: 87% !important;
}

.content {
  text-align: center;

  &--info-mrg {
    margin-bottom: 20px;
  }

  &--territorio-padding {
    padding: 20px 0;
    width: 80%;
    margin: 0 auto;

    @include desktop {
      padding: 30px 0;
    }
  }

  &__line {
    max-width: 210px;
    height: 3px;
    border-color: $blue;
    border: 0;
    margin: 0 auto;
    @include bkgr($blue);
    @include mono-spacing(margin, bottom, 18);

    @include desktop {
      max-width: 408px;
      @include mono-spacing(margin, bottom, 30);
    }

    &--home {
      max-width: unset;

      @include desktop {
        max-width: 408px;
      }
    }

    &--tariffe {
      max-width: unset;

      @include desktop {
        max-width: 408px;
      }
    }

    &--info {
      margin-bottom: 20px;
    }
  }

  &__title {
    color: $blue;
    font-size: $font31;
    line-height: $lHeight34;
    @include mono-spacing(margin, bottom, 40);

    @include desktop {
      font-size: $font42;
      line-height: 55px;
      @include mono-spacing(margin, bottom, 56);
    }

    &--mrg-sm {
      @include mono-spacing(margin, bottom, 13);
    }

    &--territorio {
      margin-bottom: 12px;
      padding-top: 30px;
    }

    &--info {
      margin-bottom: 13px;
    }

    &--ristorante {
      margin-bottom: 20px;
    }

    &--home {
      margin-bottom: 13px;
    }
  }

  &__text {
    font-size: $font15;
    // line-height: $lHeight16;
    line-height: $lHeight20;

    @include desktop {
      font-size: $font20;
      //line-height: $lHeight22;
      line-height: 24px;
      width: 75%;
      @include spacing-align(margin, 0, auto);
    }

    &--bold {
      font-family: bold;
    }

    &--territorio {
      margin-bottom: -20px;
    }

    &--info {
      width: 90%;
      margin: 0 auto;

      @include desktop {
        width: 60%;
      }
    }

    &--mrg {
      margin-bottom: 65px;
    }

    &--mrg-desktop {
      @include desktop {
        margin-bottom: 100px;
      }
    }
  }

  &__icons-wrapper {
    display: flex;
    flex-wrap: wrap;
    width: 80%;
    @include spacing-align(margin, 0, auto);
    margin-top: 40px;

    @include tablet {
      max-width: 443px;
    }

    @include desktop {
      max-width: 1171px;
      width: unset;
      margin-top: 60px;
    }

    &--no-mrg {
      margin-top: 0;
    }
  }

  &__info-card {
    width: 50%;
    //@include mono-spacing(margin, bottom, 30);

    &:last-child {
      @include desktop {
        margin-top: 0;
      }
    }

    &:nth-child(odd) {
      @include mono-spacing(margin, right, auto);
      @include desktop {
        @include mono-spacing(margin, right, 0);
      }
    }

    &:nth-child(7),
    &:nth-child(8) {
      @include mono-spacing(margin, bottom, 0);
    }

    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      @include desktop {
        @include mono-spacing(margin, bottom, 0);
      }
    }

    @include desktop {
      width: 25%;
      display: flex;
      align-items: center;
      margin-bottom: -40px;
    }
  }

  &__icon-card {
    @include spacing-align(margin, 0, auto);
    background-color: transparent;
    max-width: 120px;

    @include desktop {
      max-width: 110px;
      margin: 0;
    }

    &--king-size {
      max-width: 64px;
      width: 100%;
      margin: 0 26px 0 20px;
      background-color: white;
      border-radius: 30px;
      padding: 5px 14px;

      @include desktop {
      }
    }
  }

  &__icon-text {
    @include spacing-align(margin, 0, auto);
    font-size: $font15;
    line-height: $lHeight16;

    @include desktop {
      text-align: left;
      margin: 0;
      font-size: $font19;
      line-height: $lHeight18;
      margin-left: -15px;
    }
  }
}

@font-face {
  font-family: regular;
  src: url('../fonts/quattrocento-sans-regular.ttf') format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: bold;
  src: url('../fonts/quattrocento-bold.ttf') format('truetype');
  font-weight: 500;
}

* {
  box-sizing: border-box;
}

body,
html {
  height: 100%;
  width: 100%;
}

html {
  font-size: 62.5%;
}

body {
  font-family: 'regular';
  font-weight: 400;
  color: $black;
  font-size: 1.6rem;
  --grey-100: #e4e9f0;
  --grey-300: #b5c0cd;
}

main {
  position: relative;
  min-height: 100vh;
  background-color: $whiteHotel;
}

img {
  width: 100%;
  height: auto;
}

a {
  text-decoration: none;
  color: #fff;
}

li {
  list-style: none;
}

i {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: bold;
  font-weight: 500;
}

footer {
  @include bkgr($blue);
}

strong {
  font-family: bold;
}

.dayuse {
  background-color: $white;
  visibility: hidden;
  opacity: 0;
  display: none;

  @include desktop {
    display: unset;
    position: absolute;
    width: 100%;
    @include mono-spacing(margin, top, 16);
    left: 0;
  }

  &--active {
    @include spacing-align(padding, 15, 20);
    visibility: visible;
    opacity: 1;
    position: absolute;
    width: 101.2%;
    z-index: 2;
    bottom: -2px;
    transform: translateX(-2px);
    border: 2px solid $grey;
    @include desktop {
      @include mono-spacing(margin, top, 16);
      display: unset;
      transform: translateX(-2px);
    }
  }

  &--active-input {
    visibility: visible;
    opacity: 1;
    @include spacing-align(padding, 15, 15);
    outline: none;
    position: absolute;
    border: 2px solid $grey;
    bottom: -2px;
    width: 101.3%;
    transform: translateX(-2px);

    @include desktop {
      @include mono-spacing(margin, top, 16);
      display: unset;
      width: 101%;
      border: 2px solid $grey;
      padding: 14px;
      transform: translateX(-2px);
    }
  }

  &__textarea {
    outline: 0;
    font-family: regular;
    padding: 15px 35px;
    font-size: 16px;
    resize: none;
    margin: 0 auto;
    width: 100%;
    border: 1px solid $grey;

    &::placeholder {
      font-size: 16px;
    }
  }

  &__textarea-container {
    position: relative;
  }

  &__textarea-icon {
    width: 20px;
    position: absolute;
    top: 14px;
    left: 7px;
  }

  &__input-time {
    border: none;
    font-size: $font15;
    width: 100%;
    @include mono-spacing(margin, bottom, 16);
    outline: 0;
  }

  &__button {
    display: none;
    outline: 0;
    border: 2px solid $blue;
    border-radius: 36px;
    width: 60px;
    @include mono-spacing(margin, left, auto);
    @include mono-spacing(margin, top, 20);
    padding: 9px 8px;
    @include bkgr($white);
    @include clr($blue);
    cursor: pointer;
    font-family: bold;
    text-align: center;
    font-size: 19px;
    line-height: 22px;

    @include desktop {
      @include spacing-align(padding, 8, 11);
    }

    &--send {
      width: 100px;
    }

    &--cruscotto {
      display: block;
      width: 60px;
      font-family: bold;

      @include desktop {
        margin-bottom: 0;
        width: 62px;
        border-radius: 25px;
        padding: 9px 15px;
      }
    }
  }

  &__info {
    font-size: $font14;
    line-height: $lHeight18;
    @include clr($blue);
    @include mono-spacing(margin, bottom, 14);

    &:first-child {
      text-align: left;
    }
  }

  &__input {
    font-family: regular;
    border: none;
    font-size: $font14;
    line-height: $lHeight18;
    display: block;
    @include mono-spacing(padding, bottom, 14);
    width: 100%;
    outline: 0;

    &::placeholder {
      font-size: $font14;
      line-height: $lHeight18;
    }
  }

  &__checkbox-wrapper {
    display: flex;
  }

  &__checkbox-text {
    @include mono-spacing(margin, right, 6);
    font-size: $font14;
    line-height: $lHeight21;
  }

  &__checkbox-box {
    display: flex;
    align-items: center;
    @include mono-spacing(margin, right, auto);

    &:last-child {
      @include mono-spacing(margin, right, 0);
    }
  }

  &__input-wrapper {
    display: flex;
    align-items: center;
    border-bottom: 1px solid $grey;
    margin-bottom: 14px;
    cursor: auto;
    width: 100%;

    &--no-border {
      border-bottom: 0;
    }
  }

  &__input-img {
    width: 20px;
    padding-bottom: 14px;
    margin-right: 5px;
    margin-left: 7px;
  }

  &__calendar-long-stay {
    margin-top: 378px;

    @include desktop {
      margin-top: 0;
    }
  }

  &--calendar-day-use {
    margin-top: 380px;

    @include desktop {
      margin-top: 0;
    }
  }

  &__hour-day-use {
    margin-top: 374px;

    @include desktop {
      margin-top: 0;
    }
  }

  &__info-day-use {
    margin-top: 374px;

    @include desktop {
      margin-top: 0;
    }
  }

  &__sendform {
    width: 100%;
    background-color: $white;
    border: 0;
    outline: 0;
    color: $blue;
    font-family: bold;
    font-size: $font15;
    line-height: $lHeight16;
    cursor: pointer;

    @include desktop {
      font-size: $font19;
    }
  }

  &__sendform-container {
    border: 2px solid $grey;
    display: flex;
    padding: 0 15px;
    background-color: $white;

    &:hover {
      border: 2px solid $blue;
    }

    @include desktop {
      width: calc(25% - 18px);
    }
  }

  &__sendform-img {
    width: 15px;
    transform: translateX(0);
    transition: transform 300ms;
    @include desktop {
      width: 27px;
    }
  }
}

.dayuse__sendform-container:hover .dayuse__sendform-img {
  transform: translateX(7px);
}

#change-icon-day-use {
  transform: translateX(0);
  transition: transform 300ms;
}

.cruscotto__panel-box:hover #change-icon-day-use {
  transform: translateX(7px);
}

.info {
  @include mono-spacing(padding, top, 20);

  @include desktop {
    padding-top: 30px;
  }

  &__cards {
    margin-bottom: 110px;
    @include desktop {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__wrapper {
    text-align: left;
    border-bottom: 2px solid $blue;
    @include mono-spacing(margin, bottom, 30);
    height: fit-content;

    &:last-child {
      @include mono-spacing(margin, bottom, 0);
    }

    @include desktop {
      width: 44%;
      max-width: 460px;
      @include mono-spacing(margin, bottom, 60);

      &:nth-child(3) {
        @include mono-spacing(margin, bottom, 0);
      }
      &:nth-child(4) {
        @include mono-spacing(margin, bottom, 0);
      }
    }

    &:nth-child(odd) {
      @include mono-spacing(margin, right, auto);
    }
  }

  &__title {
    font-size: $font20;
    line-height: $lHeight22;
    @include clr($blue);
    @include mono-spacing(margin, bottom, 20);

    @include desktop {
      font-size: $font31;
      line-height: $lHeight34;
      @include mono-spacing(margin, bottom, 12);
    }
  }

  &__text {
    font-size: $font15;
    line-height: $lHeight16;
    @include mono-spacing(margin, bottom, 18);

    @include desktop {
      font-size: $font19;
      line-height: $lHeight21;
      @include mono-spacing(margin, bottom, 20);
    }
  }

  &__wrapper-box {
    @include mono-spacing(padding, bottom, 21);
    @include desktop {
      @include mono-spacing(padding, bottom, 22);
    }
  }

  &__expand {
    display: flex;
    align-items: center;
  }

  &__content-text {
    font-family: bold;
    @include clr($blue);
    font-size: $font15;
    line-height: $lHeight16;

    @include desktop {
      font-size: $font20;
      line-height: $lHeight22;
    }
  }

  &__icon {
    border-radius: 42px;
    //@include spacing(padding, 6, 14, 5, 14);
    @include bkgr($white);
    @include mono-spacing(margin, left, auto);
    vertical-align: middle;
    cursor: pointer;
    width: 45px;

    @include desktop {
      width: 50px;
    }
  }

  &__content-container {
    height: 0;
    visibility: hidden;
    opacity: 0;
    padding: 0;
    @include clr($white);
    transition: all 400ms, height 400ms, opacity 0ms, visibility 400ms;

    @include desktop {
      visibility: hidden;
      align-items: center;
      flex-direction: row;
      justify-content: unset;
      padding: 0;
      @include spacing-align(margin, 0, auto);
    }

    &--active {
      visibility: visible;
      height: 150px;
      opacity: 1;
      transition: all 400ms, height 400ms, opacity 400ms, visibility 400ms;
      display: flex;
      align-items: center;

      @include desktop {
        @include spacing-align(margin, 30, 0);
        display: flex;
      }
    }
  }

  &__content-wrapper {
    padding: 0;
  }
}

.contact {
  width: 100%;
  max-width: 340px;
  @include spacing-align(margin, 0, auto);
  margin-top: 26px;
  @include mono-spacing(padding, bottom, 17);
  @include bkgr($white);

  @include tablet {
    max-width: 443px;
  }

  @include desktop {
    max-width: 1169px;
    margin-top: 30px;
  }

  &__map-container {
    width: 100%;
  }

  &__map {
    width: 100%;
    height: 340px;

    @include desktop {
      height: 600px;
    }
  }

  &__input-card {
    border: 2px solid $grey;
    @include spacing(margin, 0, 19, 12, 19);
    @include spacing-align(padding, 12, 15);
    display: flex;
    align-items: center;
    font-size: $font15;
    line-height: $lHeight16;
    font-family: bold;
    transition: transform 300ms;

    &:first-child {
      @include mono-spacing(margin, top, 20);
    }

    &:last-child {
      @include mono-spacing(margin, bottom, 0);
      cursor: pointer;
    }

    @include desktop {
      font-size: $font20;
      line-height: $lHeight22;
      @include spacing(margin, 0, 30, 20, 30);
    }

    &--button:hover {
      border: 2px solid $blue;
    }
  }

  &__textarea {
    resize: none;
    border: 2px solid $grey;
    @include spacing(margin, 0, 30, 20, 19);
    @include spacing(padding, 20, 20, 0, 46);
    outline: 0;
    width: 100%;
    max-width: 302px;
    height: 130px;
    font-size: $font15;
    line-height: $lHeight16;
    font-family: regular;

    &::placeholder {
      font-family: regular;
      color: $grey10;
    }

    @include tablet {
      max-width: 405px;
    }

    @include desktop {
      max-width: 1109px;
      @include spacing(margin, 0, 30, 20, 30);
      height: 250px;
      @include spacing-align(padding, 15, 50);
      font-size: $font20;
      line-height: $lHeight22;
    }
  }

  &__textarea-container {
    position: relative;
  }

  &__textarea-icon {
    position: absolute;
    width: 20px;
    top: 20px;
    left: 35px;

    @include desktop {
      top: 17px;
      left: 45px;
    }
  }

  &__input {
    border: 0;
    outline: 0;
    width: 100%;
    font-size: $font15;
    line-height: $lHeight16;
    font-family: regular;
    @include clr($black);
    @include desktop {
      font-size: $font20;
      line-height: $lHeight22;
    }

    &::placeholder {
      font-size: $font15;
      line-height: $lHeight16;
      font-family: regular;
      @include clr($grey10);
      @include desktop {
        font-size: $font20;
        line-height: $lHeight22;
      }
    }

    &--button {
      @include bkgr(transparent);
      font-size: $font15;
      line-height: $lHeight16;
      color: $blue;
      font-family: bold;

      @include desktop {
        font-size: $font20;
        line-height: $lHeight22;
      }
    }

    &:last-child {
      cursor: pointer;
      text-align: left;
    }
  }

  &__icon {
    @include mono-spacing(margin, right, 15);
    @include mono-spacing(margin, top, 2);
    transform: translateX(0);
    transition: transform 300ms;
  }
}

.contact__input-card:hover #contact-icon-arrow {
  transform: translateX(7px);
  transition: transform 300ms;
}

[value="Invia l'Email"] {
  font-size: $font15;
  line-height: $lHeight16;
  font-family: bold;
  @include clr($blue);

  @include desktop {
    font-size: $font20;
    line-height: $lHeight22;
  }
}

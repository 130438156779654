.navbar {
  width: 100%;
  height: 68px;
  display: flex;
  align-items: center;
  position: fixed;
  z-index: 20;
  bottom: 0;
  font-family: bold;
  @include bkgr($grey);
  @include desktop {
    background: linear-gradient(
      180deg,
      rgba(32, 34, 46, 0.15) 0%,
      rgba(32, 34, 46, 0.15) 81.77%,
      rgba(32, 34, 46, 0.15) 100%
    );
  }

  @include desktop {
    top: 0;
    height: 55px;
  }

  &__content {
    width: 80%;
    max-width: 1169px;
    @include spacing-align(margin, 0, auto);
    display: flex;
    align-items: center;

    @include desktop {
      width: 85%;
    }
  }

  &__logo-wrapper {
    @include mono-spacing(margin, right, auto);
    font-weight: 500;
    display: none;

    @include desktop {
      display: flex;
      align-items: center;
    }
  }

  &__logo-text {
    @include clr($white);
    font-weight: 500;
    transition: color 250ms;
    font-size: $font19;
    line-height: $lHeight21;
  }

  &__links-wrapper {
    width: 100%;
    max-width: 443px;
    display: flex;
    @include spacing-align(margin, 0, auto);
    height: 43px;

    @include desktop {
      @include mono-spacing(margin, '', 0);
      height: unset;
    }
  }

  &__link {
    @include mono-spacing(margin, left, auto);
    cursor: pointer;
    display: none;

    &:last-child {
      @include mono-spacing(margin, left, 0);
    }

    @include desktop {
      display: block;
    }
  }

  &__link-item {
    @include clr($white);
    transition: color 250ms;
    font-size: $font19;
    line-height: $lHeight21;

    &:hover {
      @include clr($blue);
      //text-decoration: underline;
    }
  }

  &__link-item-img {
    margin-top: 4px;
  }

  &__link-img {
    @include mono-spacing(margin, right, auto);
    cursor: pointer;
    border: 3px solid transparent;
    @include spacing-align(padding, 10, 18.5);
    border-radius: 100px;
    display: flex;
    align-items: center;

    &:last-child {
      @include mono-spacing(margin, right, 0);
    }

    &:hover .navbar__link-item-img path {
      fill: $blue;
    }

    &:hover .navbar__link-item-img .icon-stroke {
      fill: none;
      stroke: $blue;
    }

    &:hover {
      border-color: $blue;
    }

    @include desktop {
      display: none;
    }

    &--active {
      border-color: $blue;

      .navbar__link-item-img path {
        fill: $blue;
      }

      .navbar__link-item-img .icon-stroke {
        fill: none;
        stroke: $blue;
      }
    }
  }
}

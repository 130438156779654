// Breakpoints
$small-phone-width: 320px;
$phone-landscape-width: 480px;
$phablet-width: 670px;
$tablet-width: 768px;
$large-tablet-width: 960px;
$desktop-width: 1024px;
$desktop-medium-width: 1280px;
$desktop-large-width: 1440px;
$desktop-xlarge-width: 1920px;

$difference: 0.1rem;

// Font-size
$font14: (1.4rem);
$font15: (1.5rem + 0.2rem);
$font19: (1.9rem - $difference);
$font20: (2rem - $difference);
$font21: (2.1rem - $difference);
$font25: (2.5rem - $difference);
$font28: (2.8rem - $difference);
$font31: (3.1rem - $difference);
$font42: (4.2rem - $difference);

// LineHeight

$lineDifference: 2px;
$lHeight16: 16px;
$lHeight18: 18px;
$lHeight19: 19px;
$lHeight20: 20px;
$lHeight21: 21px;
$lHeight22: 22px;
$lHeight23: 23px;
$lHeight27: 27px;
$lHeight34: 34px;
$lHeight46: 46px;

// Colors
$white: #fff;
$black: #20222e;

$black90: #20222e;
$blue: #356575;
$darkgreen: #677063;
$brown: #a38b71;
$yellow: #f0b653;
$grey: #e4e9ee;
$grey10: #b0b7c2;
$yellowHotel: #beab8e;
$whiteHotel: #f5f7fa;

.territorio {
  text-align: center;

  &--no-mrg {
    margin-bottom: 0;
  }

  &__cards-wrapper {
    &--flex {
      @include desktop {
        display: flex;
      }
    }

    &--mrg {
      margin-top: 25px;
    }
  }

  &__card {
    @include mono-spacing(padding, top, 20);
    width: 100%;
    @include desktop {
      @include mono-spacing(padding, top, 30);
    }
  }

  &__title {
    font-size: $font31;
    line-height: $lHeight34;
    @include clr($blue);
    @include mono-spacing(margin, bottom, 10);

    @include desktop {
      font-size: $font42;
      line-height: $lHeight46;
      @include mono-spacing(margin, bottom, 13);
    }
  }

  &__text-content {
    @include mono-spacing(margin, bottom, 19);
    font-size: $font15;
    line-height: $lHeight16;

    @include desktop {
      @include mono-spacing(margin, bottom, 30);
      font-size: $font20;
      line-height: $lHeight22;
    }
  }

  &__img-container {
    height: 400px;
  }

  &__img {
    height: 100%;
    @include fit-image;
  }

  &__info-wrapper {
    @include spacing-align(padding, 20, 0);
    @include bkgr($blue);
    display: flex;
    flex-direction: column;
    align-items: center;

    &--height-fix {
      height: 64px;
    }

    &--display {
      @include desktop {
        display: none;
      }
    }
  }

  &__info-content {
    @include mono-spacing(margin, bottom, 20);
    font-family: regular;
    font-size: $font15;
    line-height: $lHeight20;

    @include desktop {
      font-size: $font20;
      line-height: 24px;
    }

    &--consigli {
      @include clr($black);
    }

    &--bold {
      font-family: bold;
    }
  }

  &__info-expand {
    @include clr($white);
    font-family: bold;
    @include spacing-align(margin, 0, auto);
  }

  &__info-text {
    font-size: $font15;
    line-height: $lHeight16;
    display: flex;
    justify-content: center;
    align-items: center;

    @include desktop {
      font-size: $font21;
      line-height: $lHeight23;
    }
  }

  &__info-icon {
    @include mono-spacing(margin, left, 20);
    //border-radius: 42px;
    @include spacing(padding, 6, 14, 5, 14);
    //@include bkgr($white);
    //width: 43px;
    vertical-align: middle;
    cursor: pointer;
    width: 70px;
    @include desktop {
      @include mono-spacing(margin, left, 30);
      width: 75px;
    }
  }

  &__info-content-container {
    height: 0;
    visibility: hidden;
    opacity: 0;
    padding: 0;
    @include clr($white);
    transition: all 400ms, height 400ms, opacity 0ms, visibility 400ms;

    @include desktop {
      visibility: hidden;
      flex-direction: row-reverse;
      align-items: center;
      justify-content: space-around;
      @include spacing-align(margin, 0, auto);
      padding: 0;
    }

    &--active {
      display: flex;
      align-items: center;
      visibility: visible;
      height: 320px;
      opacity: 1;
      transition: all 400ms, height 400ms, opacity 400ms, visibility 400ms;
      width: 100%;

      @include desktop {
        height: 300px;
        @include spacing-align(padding, 30, 0);
        display: flex;
      }
    }
  }

  &__info-content-wrapper {
    width: 100%;
    @include spacing-align(padding, 0, 57);
    max-width: 900px;
    @include spacing-align(margin, 0, auto);

    @include desktop {
      width: 50%;
      @include spacing-align(padding, 0, 50);
      margin: 0;
    }

    &--display {
      display: none;
      @include desktop {
        display: block;
      }
    }
  }

  &__info-image-container {
    display: none;

    @include desktop {
      display: block;
    }
  }

  &__info-image-wrapper {
    max-width: 300px;
  }

  &__info-content-container--active-info {
    height: 470px;

    @include desktop {
      height: 450px;
    }
  }

  // &__cards-wrapper .info__title {
  //   font-size: $font31;
  //   line-height: $lHeight34;

  //   @include desktop {
  //     font-size: $font42;
  //     line-height: $lHeight46;
  //   }
  // }
}

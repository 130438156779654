.cruscotto {
  width: 100%;
  position: absolute;
  bottom: 0;
  transform: translateY(120px);
  z-index: 3;
  filter: drop-shadow(4px 13px 25px rgba(53, 101, 117, 0.3));
  @include desktop {
    transform: translateY(91px);
  }

  @include desktop-medium {
    transform: translateY(99px);
  }

  &__container {
    width: 90%;
    max-width: 340px;
    @include spacing-align(margin, 0, auto);

    @include tablet {
      width: 70%;
      max-width: 500px;
    }

    @include desktop {
      width: 95%;
      max-width: 1172px;

      @include desktop-medium {
        width: 90%;
      }
    }
  }

  &__panel-wrapper {
    display: flex;
    align-items: center;
    // transition: all 2s;
    // -webkit-transition: all 2s ease;
    // -moz-transition: all 2s ease;

    @include desktop {
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &--height {
      height: 100%;
    }

    &--padding {
      @include spacing-align(padding, 12, 15);

      @include desktop {
        @include spacing-align(padding, 12, 6);
      }

      @include desktop-medium {
        @include spacing-align(padding, 16, 15);
      }
    }

    &--arrival-padding {
      padding-left: 15px;

      @include desktop {
        padding-left: 6px;
      }

      @include desktop-medium {
        padding-left: 15px;
      }
    }
  }

  &__panel {
    width: 50%;
    height: 60px;
    @include spacing-align(padding, 7, 22);
    @include bkgr($white);
    display: flex;
    align-items: center;
    @include clr($black);
    cursor: pointer;

    @include desktop {
      @include spacing-align(padding, 7, 30);
    }

    &--longStay {
      @include bkgr($grey);
      @include clr(rgba(53, 101, 117, 0.6));
    }

    &--dayUse {
      @include bkgr($grey);
      @include clr(rgba(53, 101, 117, 0.6));
    }

    &--longStay-icon {
      width: 30px;
      margin-right: 12px;
      margin-bottom: 5px;

      @include desktop {
        width: unset;
        margin: 0;
      }
    }

    &--dayUse-icon {
      width: 33px;

      @include desktop {
        width: unset;
        margin-top: 8px;
      }
    }
  }

  &__panel-text-title {
    @include mono-spacing(margin, left, auto);
    font-size: 1.4rem;
    line-height: $lHeight16;
    color: $blue;
    @include tablet {
      @include spacing-align(margin, 0, auto);
      text-align: center;
    }

    @include desktop {
      font-size: $font21;
      line-height: $lHeight23;
    }
  }

  &__panel-settings {
    @include spacing-align(padding, 17, 19);
    @include bkgr($white);
    height: 100%;
    display: none;

    @include desktop {
      align-items: center;
      @include spacing-align(padding, 20, 15);
    }

    @include desktop-medium {
      @include spacing-align(padding, 20, 30);
    }

    &--active {
      display: block;

      @include desktop {
        display: flex;
      }
    }

    &--active-center {
      @include desktop {
        justify-content: center;
      }
    }
  }

  &__panel-box {
    width: 100%;
    border: 2px solid $grey;
    @include mono-spacing(margin, bottom, 12);
    @include spacing-align(padding, 12, 15);
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: $font15;
    line-height: $lHeight16;
    position: relative;
    display: flex;
    align-items: center;
    position: relative;
    height: 47px;

    &--block {
      @include desktop {
        display: block;
      }
    }

    &:last-child {
      @include mono-spacing(margin, bottom, 0);
    }

    &--flex {
      display: flex;
    }

    @include desktop {
      @include mono-spacing(margin, bottom, auto);
      @include mono-spacing(margin, right, 18);
      font-size: $font19;
      @include spacing-align(padding, 12, 6);
      height: 59px;

      &:last-child {
        @include mono-spacing(margin, right, 0);
      }
    }

    @include desktop-medium {
      font-size: $font20;
      line-height: $lHeight22;
      @include spacing-align(padding, 16, 15);
    }

    &--no-padding {
      padding: 0;
    }

    &--editPadding {
      @include spacing-align(padding, 12, 15);

      @include desktop {
        @include spacing-align(padding, 12, 6);
      }

      @include desktop-medium {
        @include spacing-align(padding, 16, 15);
      }
    }

    &--active-width {
      @include desktop {
        width: calc(27% - 18px);
      }
    }
  }

  &__panel-guest-count {
    display: flex;
    align-items: center;
    width: 90px;
    justify-content: space-between;
  }

  &__guest-count {
    @include spacing-align(margin, 0, 13);
  }

  &__icons {
    margin-right: 15px;

    @include desktop {
      width: 23px;
      height: 23px;
    }

    &--calendar {
      @include desktop {
        width: 26px;
        height: 27px;
      }
    }

    &--guest {
      @include desktop {
        width: 21px;
      }
    }

    &--arrow {
      width: 14px;

      @include desktop {
        width: 23px;
      }
    }
  }

  &__guest-modify {
    cursor: pointer;
  }

  &__panel-text {
    @include mono-spacing(margin, right, auto);
    @include clr($black);
    border: none;
    outline: none;
    font-size: 1.5rem;
    line-height: $lHeight16;
    font-family: regular;

    @include desktop {
      font-size: 1.7rem;
    }

    &::placeholder {
      color: $black;
    }

    &--bold {
      font-family: bold;
      @include clr($blue);
    }
  }

  &__panel-button {
    display: flex;
    align-items: center;
    width: 100%;

    &--padding {
      @include spacing-align(padding, 12, 15);

      @include desktop {
        @include spacing-align(padding, 12, 6);
      }

      @include desktop-medium {
        @include spacing-align(padding, 16, 15);
      }
    }
  }
  &__panel-button-text {
    font-family: bold;
    @include clr($blue);
  }
}

#js-panel-box:hover {
  border: 2px solid $blue;
}

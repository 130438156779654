.calendar {
  background-color: $white;
  margin: 0;
  visibility: hidden;
  opacity: 0;

  @include desktop {
    display: unset;
    position: absolute;
    width: 100%;
    @include mono-spacing(margin, top, 16);
    left: 0.3px;
  }
}

.calendar__date-input {
  border: none;
  outline: none;
  font-size: $font14;
  cursor: pointer;
  font-family: regular;

  @include desktop {
    font-size: $font19;
  }

  &::-webkit-calendar-picker-indicator {
    padding: 5px;
    cursor: pointer;
  }
}

.calendar--active {
  @include mono-spacing(margin, top, 20);
  visibility: visible;
  opacity: 1;
  height: 325px;
  max-height: 325px;

  @include desktop {
    @include mono-spacing(margin, top, 12);
    height: unset;
    height: 340px;
    max-height: 360px;
  }
}

.calendar--active-input {
  @include mono-spacing(margin, top, 20);
  visibility: visible;
  opacity: 1;
  height: 325x;
  max-height: 325px;

  @include desktop {
    @include mono-spacing(margin, top, 12);
    height: unset;
    height: 130px;
    transform: translate(-3px, -28px);
    border-bottom: 2px solid $blue;
    border-left: 2px solid $blue;
    border-right: 2px solid $blue;
    width: 101.5%;
  }
}

.calendar-month {
  position: relative;
  background-color: var(--grey-200);
  border: solid 1px var(--grey-200);
  display: none;

  @include desktop {
    @include spacing-align(padding, 0, 14);
  }
}

.calendar-month-header {
  display: flex;
  justify-content: space-between;
  background-color: #fff;
  padding: 10px;
}

.calendar-month-header-selected-month {
  font-size: $font15;
  font-weight: 600;

  @include desktop {
    font-size: $font19;
  }
}

.calendar-month-header-selectors {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 40px;
  text-align: center;
  color: $blue;
}

.calendar-month-header-selectors > * {
  cursor: pointer;
  width: 100%;
}

.day-of-week {
  color: $blue;
  font-size: $font15;
  background-color: #fff;
  padding-bottom: 5px;
  padding-top: 10px;

  @include desktop {
    font-size: $font19;
  }
}

.day-of-week,
.days-grid {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.day-of-week > * {
  text-align: right;
  padding-right: 10px;
}

.days-grid {
  height: 100%;
  position: relative;
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  border-top: solid 1px var(--grey-200);
}

.calendar-day {
  position: relative;
  min-height: 30px;
  font-size: 1.3rem;
  background-color: #fff;
  color: $black;
  margin-right: 14px;
  display: flex;
  align-items: center;
  justify-content: center;

  @include desktop {
    font-size: $font15;
  }

  &--today {
    background-color: $blue;
  }
}

.calendar-day > span {
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: var(--day-label-size);
  height: var(--day-label-size);
}

.calendar-day--not-current {
  background-color: var(--grey-100);
  color: var(--grey-300);
}

.calendar-day--today > span {
  color: $white;
  border-radius: 9999px;
  background-color: var(--grey-800);
}

.calendar__button {
  display: none;
  outline: 0;
  border: 2px solid $blue;
  border-radius: 40px;
  @include mono-spacing(margin, left, auto);
  @include mono-spacing(margin, top, 20);
  @include mono-spacing(margin, right, 14);
  @include spacing-align(padding, 10, 12);
  @include bkgr($white);
  @include clr($blue);
  cursor: pointer;
  width: 55px;
}

// import flatpickr from 'flatpickr';
// import { Italian } from 'flatpickr/dist/l10n/it.js';
// import { select } from 'nunjucks/src/filters';

// export const calendar = () => {
//   const d = document;

//   const hasBoxBooking = d.querySelector('.cruscotto');

//   if (!hasBoxBooking) return;

//   const dataArrivo = d.getElementById('js-calendar-2');
//   const dayUseTime = d.getElementById('dayuse-time');
//   const dayUseTime2 = d.getElementById('dayuse-time2');
//   const rangeDate = d.getElementById('js-from-to-date');
//   let arrival = '';
//   let departure = '';
//   let checkInString = '';
//   let checkOutString = '';

//   flatpickr('#flatpickr', {
//     onReady: (selected, dateStr, instance) => {
//       instance.calendarContainer.id = 'calendar-longStay';
//       document
//         .getElementById('calendar-long-stay')
//         .appendChild(instance.calendarContainer);
//       let btn = document.createElement('button');
//       btn.innerHTML = 'OK';
//       instance.calendarContainer.childNodes[1].appendChild(btn);
//       btn.id = 'button';
//       btn.classList.add('dayuse__button');
//       btn.classList.add('dayuse__button--cruscotto');
//       btn.style.dispay = 'block';
//     },
//     onOpen: (selected, dateStr, instance) => {
//       document
//         .getElementById('calendar-long-stay')
//         .classList.add('dayuse__calendar-long-stay');
//     },
//     locale: Italian,
//     mode: 'range',
//     minDate: 'today',
//     dateFormat: 'd-m-Y',
//     onChange: (selected, dateStr, instance) => {
//       rangeDate.innerHTML = 'Dal ' + dateStr;
//     },
//     onClose: (selected, dateStr, instance) => {
//       document
//         .getElementById('calendar-long-stay')
//         .classList.remove('dayuse__calendar-long-stay');
//       updateDate(selected, dateStr, instance);
//       document.getElementById('js-from-to-date').style.color = '#356575';
//     },
//   });

//   flatpickr('#dayuse-arrive', {
//     onReady: (selected, dateStr, instance) => {
//       instance.calendarContainer.classList.add('calendar-dayuse');

//       let btn = document.createElement('button');
//       btn.innerHTML = 'OK';
//       instance.calendarContainer.childNodes[1].appendChild(btn);
//       btn.id = 'button2';
//       btn.classList.add('dayuse__button');
//       btn.classList.add('dayuse__button--cruscotto');
//       btn.style.dispay = 'block';
//       instance.calendarContainer.id = 'calendar-dayUse';
//       document
//         .getElementById('calendar-day-use')
//         .appendChild(instance.calendarContainer);
//     },

//     onOpen: (selected, dateStr, instance) => {
//       instance.calendarContainer.childNodes[0].classList.add(
//         'calendar-dayuse__month'
//       );
//       instance.calendarContainer.childNodes[1].classList.add(
//         'calendar-dayuse__innerContainer'
//       );
//       document
//         .getElementById('calendar-day-use')
//         .classList.add('dayuse--calendar-day-use');
//     },
//     locale: Italian,
//     altFormat: 'F j, Y',
//     dateFormat: 'Y-m-d',
//     onClose: (selected, dateStr, instance) => {
//       document
//         .getElementById('calendar-day-use')
//         .classList.remove('dayuse--calendar-day-use');
//       document.getElementById('dayuse-arrive').style.color = '#356575';
//     },
//   });

//   flatpickr('#time-arrive', {
//     onReady: (selected, dateStr, instance) => {
//       instance.calendarContainer.classList.add('arrival');
//       instance.calendarContainer.remove();

//       document
//         .getElementById('time-arrive')
//         .appendChild(instance.calendarContainer);
//     },
//     onOpen: (selected, dateStr, instance) => {
//       instance.calendarContainer.classList.add('time-arrive');
//     },
//     locale: Italian,
//     enableTime: true,
//     noCalendar: true,
//     dateFormat: 'H:i',
//     time_24hr: true,
//     onChange: (selected, dateStr, instance) => {
//       dayUseTime.placeholder = dateStr;
//       document.getElementById('text-to-hour').value = dateStr;
//       document
//         .getElementById('text-to-hour')
//         .classList.remove('cruscotto__panel-text--bold');
//       // if (window.matchMedia('(min-width: 1024px)')) {
//       //   document.getElementById('text-to-hour').style.width = '39px';
//       // }
//       //  else {
//       document.getElementById('text-to-hour').style.width = '46px';
//       // }
//       document.getElementById('text-to-hour').style.fontFamily = 'regular';
//     },
//     onValueUpdate: (selected, dateStr, instance) => {
//       dayUseTime.placeholder = dateStr;
//       document.getElementById('text-to-hour').value = dateStr;
//       document
//         .getElementById('text-to-hour')
//         .classList.remove('cruscotto__panel-text--bold');
//       document.getElementById('text-to-hour').style.color = '#356575';
//     },
//   });
//   flatpickr('#time-arrive2', {
//     onReady: (selected, dateStr, instance) => {
//       instance.calendarContainer.classList.add('departure');
//       document
//         .getElementById('time-arrive2')
//         .appendChild(instance.calendarContainer);
//     },
//     onOpen: (selected, dateStr, instance) => {
//       instance.calendarContainer.classList.add('time-arrive2');
//     },
//     locale: Italian,
//     enableTime: true,
//     noCalendar: true,
//     dateFormat: 'H:i',
//     time_24hr: true,
//     onChange: (selected, dateStr, instance) => {
//       dayUseTime2.placeholder = dateStr;
//       document.getElementById('text-to-hour-departure').value = ' - ' + dateStr;
//       document.getElementById('text-to-hour-departure').style.color = '#356575';
//       document.getElementById('text-to-hour-departure').style.fontFamily =
//         'regular';
//     },
//     onClose: (selected, dateStr, instance) => {
//       console.dir(instance);
//     },
//   });

//   document.getElementById('button').addEventListener('click', function () {
//     document.getElementById('calendar-longStay').classList.remove('open');
//     document
//       .getElementById('calendar-long-stay')
//       .classList.remove('dayuse__calendar-long-stay');
//     document.getElementById('flatpickr').classList.remove('active');
//   });

//   document.getElementById('button2').addEventListener('click', function () {
//     document.getElementById('calendar-dayUse').classList.remove('open');
//     document
//       .getElementById('calendar-day-use')
//       .classList.remove('dayuse--calendar-day-use');

//     document.getElementById('dayuse-arrive').classList.remove('active');
//   });

//   const checkInCheckOut = document.getElementById('console');
//   const addGuest = d.getElementById('add-guest');
//   const cancelGuest = d.getElementById('cancel-guest');
//   const guestTotal = d.getElementsByClassName('cruscotto__guest-count');

//   let counter = 1;

//   document.querySelector('.cruscotto__guest-count').innerHTML = `
//   ${counter}
//   `;
//   document.querySelector('.cruscotto__guest-count').setAttribute('id', counter);

//   addGuest.addEventListener('click', function () {
//     updateGuest(counter + 1);

//     if ((counter = 2)) {
//       updateGuest(2);
//     }
//   });
//   cancelGuest.addEventListener('click', function () {
//     updateGuest(counter - 1);
//     if (counter <= 1) {
//       updateGuest(1);
//     }
//   });

//   checkInCheckOut.addEventListener('click', function () {
//     checkInString = arrival.join('-');
//     checkOutString = departure.join('-');
//     const ericsoft = `https://booking.ericsoft.com/BookingEngine/Book?idh=6B9EB2DC26D6E642&lang=it&cur=EUR&`;
//     const bookingUrl = `${ericsoft}arrival=${checkInString.toString()}&departure=${checkOutString.toString()}&pax=${
//       guestTotal[0].id
//     }_0&acmp=RT0`;
//     window.open(bookingUrl);
//   });

//   const updateDate = (selected, dateStr, instance) => {
//     const minus = selected;
//     arrival = [
//       selected[0].getFullYear(),
//       minus[0].getMonth() + 1 < 10
//         ? '0' + (selected[0].getMonth() + 1)
//         : selected[0].getMonth() + 1,
//       minus[0].getDate() < 10
//         ? '0' + selected[0].getDate()
//         : selected[0].getDate(),
//     ];
//     departure = [
//       selected[1].getFullYear(),
//       minus[1].getMonth() + 1 < 10
//         ? '0' + (selected[1].getMonth() + 1)
//         : selected[1].getMonth() + 1,
//       minus[1].getDate() < 10
//         ? '0' + selected[1].getDate()
//         : selected[1].getDate(),
//     ];
//   };

//   const updateGuest = (counterGuest) => {
//     counter = counterGuest;

//     document.querySelector('.cruscotto__guest-count').id = counter;
//     document.querySelector('.cruscotto__guest-count').innerHTML = `
//       ${counter}`;
//   };
// };

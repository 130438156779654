.section {
  width: 80%;
  max-width: 302px;
  @include spacing-align(margin, 0, auto);
  @include spacing(padding, 60, 0, 60, 0);

  @include tablet {
    max-width: 443px;
  }

  @include desktop {
    width: 85%;
    max-width: 1169px;
    @include spacing(padding, 90, 0, 90, 0);
  }

  &--padding-home {
    @include spacing(padding, 178, 0, 60, 0);
    @include desktop {
      @include spacing(padding, 165, 0, 60, 0);
    }
  }

  &--info {
    padding-bottom: 0;
  }

  &--home-icon {
    @include desktop {
      @include spacing(padding, 90, 0, 70, 0);
    }
  }
}
